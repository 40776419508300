import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Header from "../components/es/header";
import Footer from "../components/footer";

//Icon
import { BiArrowBack } from "react-icons/bi";

//Styles
import "./../styles/privacity.scss";

const Accesibility = () => (
    <div>
        <Header checked={""} />
        <SEO
            title="Accesibilidad"
            description="Declaración de accesibilidad. En CIE estamos comprometidos para hacer nuestro sitio disponible a todos."
        />
        <section className="privacity">
            <Link to="/" className="link" > <BiArrowBack size="18" className="icon" /> Inicio </Link>
            <h1>Accesibilidad</h1>
            <article>
                <p>Estamos comprometidos con la accesibilidad, diversidad e inclusión. Creemos que todos deben poder acceder a nuestra sitio desde cualquier dispositivo sin problemas. Este sitio inclute los estándares globales en desarrollo web con estructura y protocolos de última generación.</p>
                <p>Algunos de los aspectos que hemos considerado son:</p>
                <ul role="list" >
                    <li role="listitem" >- Separación del contenido de la presentación con hojas de estilos (CSS).</li>
                    <li role="listitem" >- Estrcutura y correcto etiquetado del contenido.</li>
                    <li role="listitem" >- Uso de etiquetas como h1 (y consecuentes), ul y blockquote por uso y significado semántico y no como etiquetas de estilo o formato.</li>
                    <li role="listitem" >- No se utilizan tablas para el diseño y estructuración del sitio.</li>
                    <li role="listitem" >- El sitio funciona con estilos responsivos para adaptarse a cualquier pantalla de forma correcta.</li>
                    <li role="listitem" >- Utilizamos texto significativo en los enlaces.</li>
                    <li role="listitem" >- Paletas de estilos y colores que permiten una buena lectura.</li>
                    <li role="listitem" >- El uso de Javascript es únicamente con propósitos decorativos y no afecta la correcta funcionalidad del sitio.</li>
                    <li role="listitem" >- No se utilizan iframes.</li>
                    <li role="listitem" >- Todas las imágenes del sitio incluyen un “alt”.</li>
                    <li role="listitem" >- Compatibilidad con todos los exploradores.</li>
                </ul>
                <p>Todas las páginas del sitio utilizan los requerimientos básicos de navegación en estructura para legibilidad y uso. La estructuración del sitio incluye títulos y jerarquías aptas para la navegación y mejoras de SEO, siguiendo toda guía de diseño y desarrollo web.</p>
                <p>Ya se si usas tecnologías asistivas como un lectore de pantallas, reconocimiento de voz o pulsadores, queremos que nuestro sitio sea enteramente accsible para usted. Por lo que si tiene alguna duda o dificultad, por favor contáctenos en <a href="mailto:contacto@cienegocios.com?subject=Accesibilidad">contacto@cienegocios.com</a> y estaremos felices de poder apoyarle. </p>
            </article>
        </section>
        <Footer lang="es" />
    </div>
)

export default Accesibility;
